@import "~bootstrap/scss/functions";

$primary: #017999;
$warning: #ffe083;

$enable-shadows: true;

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


@import "~bootstrap";