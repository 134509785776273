.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

main {
  /*background-color: #282c34;*/
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  font-size: 4px;
  margin: 8px auto;
  text-indent: -9999em;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  background: #0d6efd;
  background: -moz-linear-gradient(left, #0d6efd 10%, rgba(71,144,139, 0) 42%);
  background: -webkit-linear-gradient(left, #0d6efd 10%, rgba(71,144,139, 0) 42%);
  background: -o-linear-gradient(left, #0d6efd 10%, rgba(71,144,139, 0) 42%);
  background: -ms-linear-gradient(left, #0d6efd 10%, rgba(71,144,139, 0) 42%);
  background: linear-gradient(to right, #0d6efd 10%, rgba(71,144,139, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #0d6efd;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.light-underline {
  text-decoration: underline;
  text-decoration-color: lightgray;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-pointer input:hover {
  cursor: pointer;
}

.cursor-pointer label:hover {
  cursor: pointer;
}

.table-filter-pill {
  padding: 5px 10px;
  border: 1px solid #ced4da;
  border-radius: 7px;
}

.table-filter-pill input:hover {
  cursor: pointer;
}

.table-filter-pill label:hover {
  cursor: pointer;
}

.dropstart .dots-menu { 
  text-decoration-line: none;
  cursor: pointer;
  color: #535353;
}

.dropstart .dots-menu:hover { 
  color: white;
}

.dropstart .dots-menu::before {
  content: none;
  cursor: pointer;
}

.dropstart .dots-menu svg {
  margin-top: -5px;
}

/* .cursor-pointer:hover {
  background-color: #03073a;
} */