.td-actions {
    padding: 0.33rem;
}

.td-actions button {
    padding: 0.2rem 0.4rem;
}

.summary-line td {
    border-bottom: none;
}